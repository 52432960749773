<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted() {
      document.title = 'Boo.link - Dashboard'
    },
    beforeDestroy() {
        // document.removeEventListener('swUpdated', this.showRefreshUI);
    },
    components: {},
    created() {
	},
    methods: {},
};
</script>

<style lang="scss">
body {
  height: 100%;
  background-color: #f5f5ff!important;
}
.btn.btn-primary, .card.bg-primary {
  background-color: #7a73ff!important;
  border-radius: 90px;
  font-size: 15px;
  line-height: 20px;
}
.card {
  border-radius: 15px!important;
  box-shadow: 0 40px 90px rgba(0, 0, 0, 0.05)!important;
}

#app {
    height: 100%;
    min-height: 100vh;
    font-family: "sofia-pro", sans-serif;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: transparent!important;
    box-shadow: none!important;
    outline: none!important;
}
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
.modal-footer {
    padding: 10px 0!important;
}
.top-navbar {
  height: 70px;
  margin-bottom: 30px;
}

.color-white {
    color: white!important;
}

@import url("https://p.typekit.net/p.css?s=1&k=rgp0tkg&ht=tk&f=24539.24543.24545.24547.24549&a=17517761&app=typekit&e=css");

@font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/1416a9/00000000000000007735a15a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/1416a9/00000000000000007735a15a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/1416a9/00000000000000007735a15a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;
}

@font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/4abf65/00000000000000007735a15d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/4abf65/00000000000000007735a15d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/4abf65/00000000000000007735a15d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;
}

@font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/61b4ba/00000000000000007735a167/30/l?subset_id=2&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/61b4ba/00000000000000007735a167/30/d?subset_id=2&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/61b4ba/00000000000000007735a167/30/a?subset_id=2&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;
}

.tk-sofia-pro { font-family: "sofia-pro",sans-serif; }
</style>
