import Vue from 'vue'
import Vuex from 'vuex'
// import Account from '@/Pages/Account/store';
import feathersVuex from 'feathers-vuex'
import feathersClient from '@/feathers-client.js'

const { service, auth, FeathersVuex } = feathersVuex(feathersClient, { idField: '_id' });


Vue.use(Vuex);
Vue.use(FeathersVuex);

export default new Vuex.Store({
    state: {
        size: '',
    },
    plugins: [
        service('users', {}),
        service('authManagement', {}),
        service('links', {}),
        service('media', {}),
        service('uploads', {}),
        service('themes', {}),
        auth({
            userService: 'users'
        })
    ],

    // modules: {
    //     account: Account
    // }
})
