import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, {
    siteKey: '6LcNyCAfAAAAAJAW5EHTGYdC1SL8zGGD3xGdHgrM',
    loaderOptions: {
        autoHideBadge: true
    }
})

import Utils from './plugins/utils'
//@ts-ignore
import en from '@/translations/en'
//@ts-ignore
import ru from '@/translations/ru'
//@ts-ignore
import tr from '@/translations/tr'

import VueMoment from 'vue-moment'
import './toasts'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/fonts/feather/feather.css'
import '@/assets/css/theme.min.css'
const moment = require('moment')

let defaultLang = !process.env.DEFAULT_LANG ? 'en' : process.env.DEFAULT_LANG;

VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
    // this === VueI18n instance, so the locale property also exists here
    if (this.locale !== 'en') {
        // proceed to the default implementation
    }

    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (!teen && endsWithOne) {
        return 1;
    }

    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }

    return (choicesLength < 4) ? 2 : 3;
};

Vue.use(VueI18n)

let messages = {
    en,
    ru,
    tr
};

const i18n = new VueI18n({
    locale: defaultLang,
    messages,
    silentTranslationWarn: true
});

Object.defineProperty(Vue.prototype, '$locale', {
    get: function () {
        return i18n.locale
    },
    set: function (locale) {
        i18n.locale = locale
    }
})

Vue.use(BootstrapVue);
Vue.use(Utils, { store: store });
Vue.use(VueMoment, {
    moment
});


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        store.dispatch('auth/authenticate').then((response: any) => {
            next();
        }).catch((error: any) => {
            next({
                name: 'login',
            })
        })
    } else {
        next();
    }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
