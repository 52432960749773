import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'

const socket = io(process.env.VUE_APP_API_ENDPOINT, {
    transports: ['websocket']
});

console.log(process.env.VUE_APP_API_ENDPOINT);

const feathersClient = feathers()
    .configure(socketio(socket))
    .configure(auth({
        storage: window.localStorage
    }));

export default feathersClient
